
  import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
  import { plainToClass }       from "class-transformer";
  import { Options, Vue }       from "vue-class-component";
  import { Model }              from "vue-property-decorator";

  import moment                 from "moment";

  import UserAutocomplete     from "@/components/UserAutocomplete/UserAutocomplete.vue";
  import ShiftAutocomplete    from "@/components/ShiftAutocomplete/ShiftAutocomplete.vue";

  import { TextFilters } from "./TextFilters";

  @Options({
    name: 'TextsFilters',
    components: {
      UserAutocomplete,
      ShiftAutocomplete,
    },
  })
  export default class extends Vue {
    @Model("filters")
    advFilters: TextFilters;

    onSelectedUser() {
      this.emitFilters();
    }

    onSelectedShift() {
      this.emitFilters();
    }

    openCalendar(event) {
      (this.$refs as any).op?.toggle(event);
    }

    hideCalendar() {
      (this.$refs as any).op?.hide();
    }

    resetDateFilter() {
      this.advFilters.from_to = null;
      this.emitFilters();
    }

    get fromDate() {
      const from = this.advFilters?.from_to?.[0];

      return from ? moment(from).format("YYYY/MM/DD") : "----/--/--";
    }

    get toDate() {
      const to = this.advFilters?.from_to?.[1];

      return to ? moment(to).format("YYYY/MM/DD") : "----/--/--";
    }

    emitFilters() {
      this.saveAdvancedFilters();
      this.$emit("change", this.advFilters);
    }

    private saveAdvancedFilters() {
      sessionStorage.setItem(
        SessionStorageEnum.TEXTS_ADVANCED_FILTERS,
        JSON.stringify(this.advFilters)
      );
    }

    private restoreAdvancedFilters() {
      const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.TEXTS_ADVANCED_FILTERS)
      );

      this.advFilters = fromStorage
        ? plainToClass(TextFilters, fromStorage)
        : this.advFilters;
    }

    created() {
      this.restoreAdvancedFilters();
    }
  }
